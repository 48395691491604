const store = require('../store/index');

exports.drawImage = (evt, canvasSize) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    // deixando o canvas invisivel
    canvas.style.display = "none";
    document.body.appendChild(canvas);

    let imagem = new Image();
    // libera o download da imagem
    imagem.crossOrigin = "anonymous";
    let ctx = canvas.getContext("2d");


    let self = {
      canvasSize: { width: 1920, height: 1080 },
      proportion: { width: 0, height: 0 },
      image: { width: 1920, height: 1080 },
      drawLimits: {
        width: {
          values: [],
          max: 0,
          min: 0,
        },
        height: {
          values: [],
          max: 0,
          min: 0,
        },
      }
    }

    imagem.onerror = () => {
      // caso de algum erro na imagem
      reject(evt.snapshot);
    }

    imagem.onload = function () {
      // se vier o tamanho do canvas, usa ele, senão usa o tamanho da imagem
      if (canvasSize) {
        if (canvasSize.width && canvasSize.height) {
          canvas.width = canvasSize.width;
          canvas.height = canvasSize.height;
        }
        else {
          canvas.width = this.width;
          canvas.height = this.height;
        }
      }
      else {
        canvas.width = this.width;
        canvas.height = this.height;
      }
      // se receber for um array e possuir mais de uma posição, pega o tamanho da imagem, usa ele, senão usa o tamanho da camera
      if (Array.isArray(evt.detections) && evt.detections.length && evt.detections[0].resolution) {
        self.proportion = {
          width: canvas.width / evt.detections[0].resolution.width,
          height: canvas.height / evt.detections[0].resolution.height,
        };
      } else {
        if (evt.configCam.detectionLimits) {
          self.proportion = {
            width: canvas.width / evt.configCam.detectionLimits.width,
            height: canvas.height / evt.configCam.detectionLimits.height,
          };
        }
      }
      self.image = { width: this.width, height: this.height };
      ctx.drawImage(imagem, 0, 0, canvas.width, canvas.height);
      // usando para não desenhar a mesma região
      let ids = [];
      evt.detections.forEach(function (e) {
        // se possuir região e for um array com mais de um elemento desenha
        if (!ids.includes(e.regionId)) {
          // adiciona o id da região para não desenhar novamente
          ids.push(e.regionId);
          if (Array.isArray(e.region) && e.region.length) {
            e.region.forEach(function (r, idx) {
              // verifica se a região já foi desenhada
              self.drawLimits.width.values.push(parseInt(r[0]));
              self.drawLimits.height.values.push(parseInt(r[1]));
              /**========================= usado para debug ============================= */
              // mostrando o indice
              // ctx.lineWidth = 2;
              // ctx.strokeStyle = "rgba(255, 255, 0, 1)";
              // ctx.font = "15px Arial";
              // ctx.strokeText(
              //   `PONTO - ${idx}`.toLocaleUpperCase(),
              //   r[0] * self.proportion.width,
              //   r[1] * self.proportion.height + 10
              // );
              /**========================= usado para debug ============================= */
              if (!idx) {
                // coloca a caneta na cordenada
                ctx.moveTo(
                  r[0] * self.proportion.width,
                  r[1] * self.proportion.height
                );
                // texto
                // largura da linha
                ctx.lineWidth = 2;
                ctx.strokeStyle = "rgba(0, 255, 0, 1)";
                ctx.font = "15px Arial";
                ctx.strokeText(
                  `Região - ${e.regionId}`.toLocaleUpperCase(),
                  r[0] * self.proportion.width,
                  r[1] * self.proportion.height - 10
                );
              } else {
                // move a caneta riscando
                ctx.lineTo(
                  r[0] * self.proportion.width,
                  r[1] * self.proportion.height
                );
              }
            });

            // pegagando os valores maximos e minimos para montar a detecção
            self.drawLimits.width.max = self.drawLimits.width.values.reduce(
              (a, b) => Math.max(a, b)
            );
            self.drawLimits.width.min = self.drawLimits.width.values.reduce(
              (a, b) => Math.min(a, b)
            );
            self.drawLimits.height.max = self.drawLimits.height.values.reduce(
              (a, b) => Math.max(a, b)
            );
            self.drawLimits.height.min = self.drawLimits.height.values.reduce(
              (a, b) => Math.min(a, b)
            );
            // fecha o desenho
            ctx.closePath();
            // cor da linha
            ctx.strokeStyle = "rgba(0, 255, 0, 1)";
            // desenha os contornos
            ctx.stroke();
            // cor do preenchimento
            ctx.fillStyle = "rgba(0, 255, 0, 0.1)";
            // aplica o preenchimento
            ctx.fill();
          }
        }
        // agora é a detecção
        e.target.forEach((r) => {
          // inicia o desenho
          ctx.beginPath();
          ctx.strokeStyle = "rgba(255, 0, 0, 1)";
          ctx.strokeRect(
            r.x * self.proportion.width,
            r.y * self.proportion.height,
            r.width * self.proportion.width,
            r.height * self.proportion.height
          );
          // cor do preenchimento
          ctx.fillStyle = "rgba(255, 0, 0, 0.1)";
          // aplica o preenchimento
          ctx.fillRect(
            r.x * self.proportion.width,
            r.y * self.proportion.height,
            r.width * self.proportion.width,
            r.height * self.proportion.height
          );
          ctx.closePath();

          // pega a largura do texto
          // let textWidth = ctx.measureText(generateTitle(evt, e));
          // verifico se a altura do texto é proximo da altura da imagem, se for coloco o texto em baixo da caixa
          let heightText = r.y * self.proportion.height - 10 > 15 ? r.y * self.proportion.height - 10 : r.height * self.proportion.height + 20;
          // box escuro atrás do texto
          // ctx.fillStyle = "rgba(0, 0, 0, .7)";
          // ctx.fillRect(r.x * self.proportion.width - 2, heightText - 15, textWidth.width + 2, 20);
          // texto
          // largura da linha
          ctx.lineWidth = 2;
          ctx.strokeStyle = "rgba(255, 0, 0, 1)";
          ctx.font = "15px Arial";
          ctx.strokeText(
            generateTitle(evt, e),
            r.x * self.proportion.width,
            heightText
          );
        });
        // usado para mostrar as predições feitas pela IA
        if (Array.isArray(e.predictions) && e.predictions.length) {
          let proportion = {
            width: canvas.width / self.image.width,
            height: canvas.height / self.image.height,
          };
          // agora é a detecção
          e.predictions.forEach((r) => {
            // inicia o desenho
            ctx.beginPath();
            ctx.strokeStyle = r.alarm ? "rgba(255, 0, 255,1)" : "rgba(0, 0, 255,1)";
            ctx.strokeRect(
              r.x * proportion.width,
              r.y * proportion.height,
              r.width * proportion.width,
              r.height * proportion.height
            );
            // cor do preenchimento
            ctx.fillStyle = r.alarm ? "rgba(255, 0, 255,0.1)" : "rgba(0, 0, 255,0.1)";
            // aplica o preenchimento
            ctx.fillRect(
              r.x * proportion.width,
              r.y * proportion.height,
              r.width * proportion.width,
              r.height * proportion.height
            );
            ctx.closePath();

            // pega a largura do texto
            let textWidth = ctx.measureText(r.class.toUpperCase() + " - " + (r.confidence * 100).toFixed(1) + "%");
            // verifico se a altura do texto é proximo da altura da imagem, se for coloco o texto em baixo da caixa
            let heightText = r.y * proportion.height - 10 > 15 ? r.y * proportion.height - 10 : r.height * proportion.height + 20;
            // box escuro atrás do texto
            ctx.fillStyle = "rgba(0, 0, 0, .7)";
            ctx.fillRect(r.x * proportion.width - 2, heightText - 15, textWidth.width + 2, 20);
            // texto
            // largura da linha
            ctx.lineWidth = 2;
            ctx.strokeStyle = r.alarm ? "rgba(255, 0, 255,1)" : "rgba(0, 0, 255,1)";
            ctx.font = "15px Arial";
            ctx.strokeText(
              r.class.toUpperCase() + " - " + (r.confidence * 100).toFixed(1) + "%",
              r.x * proportion.width,
              heightText
            );
          });
        }
        if (e.isLPR) {
          let lprx = 15;
          let lpry = canvas.height - 15;
          let text = `Placa: ${e.plate} - Velocidade: ${e.speed}KM/H - Marca: ${e.VehicleSign} - COR: ${e.VehicleColor} - Modelo: ${e.VehicleSize}`.toUpperCase();
          let textWidth = ctx.measureText(text);
          ctx.fillStyle = "rgba(0, 0, 0, .8)";
          ctx.fillRect(lprx - 2, lpry - 15, textWidth.width + 4, 18);
          ctx.lineWidth = 1;
          ctx.width = 1;
          ctx.strokeStyle = "rgba(0, 0, 0,1)";
          ctx.fillStyle = "rgba(255, 0, 0,1)";
          ctx.font = "15px Arial";
          ctx.fillText(
            text,
            lprx,
            lpry,
          );
        }
      });
      let uri = canvas.toDataURL("image/jpeg", 1);
      // verifico se é IE se for não tem o metodo remove
      var isIE = /*@cc_on!@*/false || !!document.documentMode;
      if (!isIE) {
        canvas.remove();
      }
      resolve(uri);
    };
    // usado para mostrar a url do da imagem em caso de teste
    // let urlTeste = `${evt.snapshot}${store.default.getters.getImgTokenQuery}`;
    // if(urlTeste.includes("http://localhost:8080/")){
    //   urlTeste = urlTeste.replace("http://localhost:8080/", "https://heimdall.stv.com.br/");
    // }
    // if(urlTeste.startsWith("/images")){
    //   urlTeste = urlTeste.replace("/images", "https://heimdall.stv.com.br/images");
    // }
    // console.log("URL", urlTeste);
    // imagem.src = urlTeste;
    imagem.src = evt.snapshot + store.default.getters.getImgTokenQuery;
  });
}


function generateTitle(type, evt) {
  let title = '';
  title = `${type.evtName ? type.evtName : type.code}`.toLocaleUpperCase();
  if (evt.traducao) {
    title += ` - ${evt.traducao}`.toLocaleUpperCase();
  } else {
    title += ` - ${evt.type}`.toLocaleUpperCase();
  }
  if (evt.confidence) {
    title += ` ( ${evt.confidence} % )`;
  }
  return title;
}

// codigo original do atendimento para historico
// showEvent(evt) {
//   let imagem = new Image();
//   // libera o download da imagem
//   imagem.crossOrigin = "anonymous";
//   let canvas = document.getElementById("image");
//   let ctx = canvas.getContext("2d");
//   if (!evt.snapshot) {
//     return;
//   }

//   let self = this;

//   imagem.onload = function () {
//     if (evt.configCam.detectionLimits) {
//       proportion = {
//         width: canvas.width / evt.configCam.detectionLimits.width,
//         height: canvas.height / evt.configCam.detectionLimits.height,
//       };
//     }
//     self.image = { width: this.width, height: this.height };
//     // console.log(canvas.width, canvas.height, this.width, this.height);
//     ctx.drawImage(imagem, 0, 0, canvas.width, canvas.height);

//     evt.detections.forEach(function (e) {
//       e.region.forEach(function (r, idx) {
//         self.drawLimits.width.values.push(parseInt(r[0]));
//         self.drawLimits.height.values.push(parseInt(r[1]));
//         if (!idx) {
//           // coloca a caneta na cordenada
//           ctx.moveTo(
//             r[0] * self.proportion.width,
//             r[1] * self.proportion.height
//           );
//           // texto
//           // largura da linha
//           ctx.lineWidth = 2;
//           // ctx.fillStyle = "rgba(0, 127, 127, 1)";
//           ctx.strokeStyle = "rgba(0, 255, 0, 1)";
//           // ctx.textAlign = "center";
//           ctx.font = "15px Arial";
//           ctx.strokeText(
//             `Região - ${e.regionId}`.toLocaleUpperCase(),
//             r[0] * self.proportion.width,
//             r[1] * self.proportion.height - 10
//           );
//         } else {
//           // move a caneta riscando
//           ctx.lineTo(
//             r[0] * self.proportion.width,
//             r[1] * self.proportion.height
//           );
//         }
//         // self.setDrawLimits(self,r)
//       });
//       // pegagando os valores maximos e minimos para montar a detecção
//       self.drawLimits.width.max = self.drawLimits.width.values.reduce(
//         (a, b) => Math.max(a, b)
//       );
//       self.drawLimits.width.min = self.drawLimits.width.values.reduce(
//         (a, b) => Math.min(a, b)
//       );
//       self.drawLimits.height.max = self.drawLimits.height.values.reduce(
//         (a, b) => Math.max(a, b)
//       );
//       self.drawLimits.height.min = self.drawLimits.height.values.reduce(
//         (a, b) => Math.min(a, b)
//       );
//       // fecha o desenho
//       ctx.closePath();
//       // cor da linha
//       ctx.strokeStyle = "rgba(0, 255, 0, 1)";
//       // desenha os contornos
//       ctx.stroke();
//       // cor do preenchimento
//       ctx.fillStyle = "rgba(0, 255, 0, 0.1)";
//       // aplica o preenchimento
//       ctx.fill();

//       // agora é a dectecção
//       e.target.forEach((r) => {
//         // inicia o desenho
//         ctx.beginPath();
//         // gerando os valores dos desenhos baseado nos parametros
//         // let x = parseFloat(r.x) * self.drawLimits.width.max;
//         // let y = parseFloat(r.y) * self.drawLimits.height.max;
//         // let w = parseFloat(r.width) * self.drawLimits.width.max;
//         // let h = parseFloat(r.height) * self.drawLimits.height.max;
//         ctx.strokeStyle = "rgba(255, 0, 0, 1)";
//         ctx.strokeRect(
//           r.x * self.proportion.width,
//           r.y * self.proportion.height,
//           r.width * self.proportion.width,
//           r.height * self.proportion.height
//         );
//         // cor do preenchimento
//         ctx.fillStyle = "rgba(255, 0, 0, 0.1)";
//         // aplica o preenchimento
//         ctx.fillRect(
//           r.x * self.proportion.width,
//           r.y * self.proportion.height,
//           r.width * self.proportion.width,
//           r.height * self.proportion.height
//         );
//         ctx.closePath();

//         // texto
//         // largura da linha
//         ctx.lineWidth = 2;
//         // ctx.fillStyle = "rgba(0, 127, 127, 1)";
//         ctx.strokeStyle = "rgba(255, 0, 0, 1)";
//         // ctx.textAlign = "center";
//         ctx.font = "15px Arial";
//         ctx.strokeText(
//           `${evt.evtName ? evt.evtName : evt.code}`.toLocaleUpperCase(),
//           r.x * self.proportion.width,
//           r.y * self.proportion.height - 10
//         );
//       });

//       // largura da linha
//       // ctx.lineWidth = 2;
//       // ctx.strokeStyle = "rgba(255, 165, 0, 1)";
//       // ctx.textAlign = "center";
//       // ctx.font = "15px Arial";
//       // ctx.strokeText(
//       //   `Evento - ${evt.id} - ${evt.code}`.toLocaleUpperCase(),
//       //   canvas.width / 2,
//       //   canvas.height - 20
//       // );
//     });
//   };
//   // imagem.src = "http://localhost:3333/images/" + evt.snapshot;
//   imagem.src = evt.snapshot + self.$store.getters.getImgTokenQuery;
//   // console.log("http://localhost:3333/images/" + evt.snapshot);
// },