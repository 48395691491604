<template>
  <div>
    <v-card>
      <v-card-title> Relatório de acessos do sistema </v-card-title>
      <!-- FORM -->
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete :items="methods" label="Metodo" hint="Selecione o metodo" v-model="relatorio.method"
                  persistent-hint clearable prepend-inner-icon="mdi-ev-plug-type1" outlined></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete :items="[{ value: 'DESC', text: 'Decresente' }, { value: 'ASC', text: 'Cresente' }]"
                  label="Ordem" hint="Selecione a ordem do relatório" v-model="relatorio.orderBy" persistent-hint
                  clearable prepend-inner-icon="mdi-account-cog" outlined></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field label="Limite de linhas" hint="Digite um limite se for necessario" persistent-hint
                  v-model.number="relatorio.limit" clearable prepend-inner-icon="mdi-counter" type="number" step="100"
                  min="0" outlined />
              </v-col>
              <v-col>
                <v-text-field label="Data Inicial" v-model="relatorio.start" type="datetime-local" outlined></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Data Final" v-model="relatorio.end" type="datetime-local" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
            </v-row>
            <v-row>
              <v-btn color="success" text @click="loadPDF" v-show="report">
                Gerar PDF
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="load"> Buscar </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- TABELA -->
    <v-card class="mt-2" outlined>
      <v-row class="mt-2">
        <v-col>
          <v-text-field label="Pesquisar" hint="Pesquise pelo nome do usuário, nome do grupo, metodo e url" @input="filter"
            v-model="search" clearable prepend-inner-icon="mdi-magnify" class="mx-2" persistent-hint outlined/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          Quantidade de linhas: {{this.reportFiltred.length}}
        </v-col>
      </v-row>
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Usuário</th>
              <th class="text-left">Grupo</th>
              <th class="text-left">Metodo</th>
              <th class="text-left">Url</th>
              <th class="text-left">Data</th>
              <th class="text-left">Tipo</th>
              <th class="text-left">Corpo (Body)</th>
              <th class="text-left">Parametros (Params)</th>
              <th class="text-left">Query</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in reportFiltred" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.user_id }} - {{ item.userName }}</td>
              <td>{{ item.groupId }} - {{ item.groupName }}</td>
              <td>{{ item.method }}</td>
              <td>
                <v-tooltip top color="grey darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ url(item.url) }}
                    </span>
                  </template>
                  <span>{{ item.url }}</span>
                </v-tooltip>
              </td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td> {{ item.type }} </td>
              <td>
                <v-card v-show="Object.keys(item.body).length > 0">
                  <v-card-text>
                    <v-row v-for="(el, i) in explodeData(item.body)" :key="'b' + i">
                      <v-col cols="6"> {{ el.key }} </v-col>
                      <v-col cols="6"> {{ el.value }} </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
              <td>
                <v-card v-show="Object.keys(item.params).length > 0">
                  <v-card-text>
                    <v-row v-for="(el, i) in explodeData(item.params)" :key="'p' + i">
                      <v-col cols="6"> {{ el.key }} </v-col>
                      <v-col cols="6"> {{ el.value }} </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
              <td>
                <v-card v-show="Object.keys(item.query).length > 0">
                  <v-card-text>
                    <v-row v-for="(el, i) in explodeData(item.query)" :key="'q' + i">
                      <v-col cols="6"> {{ el.key }} </v-col>
                      <v-col cols="6"> {{ el.value }} </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import PDF from "@/Utils/PDF";
export default {
  name: "RelatorioEventos",
  data: () => {
    return {
      search: "",
      relatorio: {
        cameraId: [],
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        orderBy: "DESC",
        withTrat: false,
        withImage: false,
        limit: 1000,
      },
      report: [],
      reportFiltred: [],
      methods: [
        "GET",
        "POST",
        "PUT",
        "DELETE",
      ],
    };
  },
  methods: {
    load() {
      let query = "";
      for (const key in this.relatorio) {
        if (Object.hasOwnProperty.call(this.relatorio, key)) {
          if (Array.isArray(this.relatorio[key])) {
            this.relatorio[key].forEach((el) => {
              query += `${key}=${el}&`
            });
          } else {
            query += this.relatorio[key] ? `${key}=${this.relatorio[key]}&` : ''
          }
        }
      }
      this.report = [];
      this.$http(`/log?${query.substring(0, query.length - 1)}`).then((res) => {
        this.report = res.data;
        this.filter();
      });
    },
    loadPDF() {
      let filename = `Relatorio_Log_${new Date().toISOString().substring(0, 10)}`;
      this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
      this.$store.dispatch("setShowDialog", true);
      PDF.logSistema(
        this.report,
        "Relatorios de eventos",
        true,
        null,
        filename
      )
        .then(() => {
          this.$store.dispatch("setMessageDialog", "Baixando arquivo");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        })
        .catch(() => {
          // console.log(err);
          this.$store.dispatch("setMessageDialog", "Erro ao gerar relatório");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        });
    },
    url(path) {
      let retorno = path;

      let queryPosi = retorno.lastIndexOf('?');
      if (queryPosi > 0) {
        retorno = retorno.substring(0, queryPosi);
      }

      let explodedPath = retorno.split('/')
      if (!isNaN(explodedPath[explodedPath.length - 1])) {
        let lastDivider = retorno.lastIndexOf('/');
        let pathNoId = retorno.substring(0, lastDivider);
        retorno = pathNoId;
      }
      return retorno;
    },
    explodeData(json) {
      let retorno = [];
      for (const key in json) {
        if (Object.hasOwnProperty.call(json, key)) {
          retorno.push({ key, value: json[key] });
        }
      }
      return retorno;
    },
    filter() {
      if (!this.search) {
        this.reportFiltred = this.report;
        return;
      }
      const filterModelStr = (str = "", search = "") => str.toLowerCase().includes(search.toLowerCase());
      this.reportFiltred = this.report.filter((el) => {
        return filterModelStr(el.url, this.search)
          || filterModelStr(el.method, this.search)
          || filterModelStr(`${el.userName}`, this.search)
          || filterModelStr(`${el.groupName}`, this.search);
      });
    },
  },
  computed: {

  },
  mounted() {

  },
  beforeMount() {

  },
};
</script>

<style></style>